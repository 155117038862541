@import url('https://fonts.googleapis.com/css2?family=Parisienne&family=Charm&family=Sofia&family=Allura&family=Baskervville&display=swap');
.weddingApp {
  max-width: 800px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: rgba(255, 248, 247, 1);
  font-family: 'Charm', cursive;
  overflow: hidden;
  box-shadow: 5px 0 16px -8px rgba(31, 73, 125, 0.8), -5px 0 35px -12px rgba(31, 73, 125, 0.8);
}
input, button { outline: none; }
.home{  
  height: 100%;
  position: relative;
  font-family: 'Baskervville';
}
.cherryFlower{
  background: url('./images/cherry.png');
  background-size: 58%;
  width: 100%;
  background-repeat: no-repeat;
  height: 150px;
  position: absolute;
  background-position: 0 0;
  top: 0;
  transform: rotate(169deg);
}
.cherryFlowerBtm{
  background: url('./images/cherry.png');
  background-size: 58%;
  width: 100%;
  background-repeat: no-repeat;
  height: 163px;
  position: absolute;
  background-position: 0 0;
  bottom: 0;
  transform: rotate(356deg);
}
.overlay{
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.welcome{
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.wl-note, .location{
  font-size: 2rem;
  color: #c47a8b;
}
.couples{
  font-size: 3rem;
  color: #000000;
  font-weight: 700;
  width: 300px;
  display: flex;
  justify-content: space-around;
  font-family: 'Allura';
}
.cplImg{
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thankyou-note{
  font-family: 'Charm';
  font-size: 2em;
  color: #c47a8b;
  padding: 20px 0;
}
.cplImg.thnk{
  height: 285px;
}
.cplImg .img{
  background-image: url('./images/rj.png');
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.nav-btn-container{
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0;
  padding: 20px 0;
  margin-top: 20px;
  cursor: pointer;
}
.nav-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  color: #ffffff;
  text-align: center;
  font-size: 1.1rem;
  font-style: italic;
  background: #e992a6;
  padding: 10px 40px;
  text-decoration: none;
  border-radius: 32px;
}
.my-table{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
}
.my-table .leaf1{
  background-image: url('./images/cherry-blossom.png');
  background-repeat: no-repeat;
  height: 650px;
  background-size: 100%;
  position: absolute;
  width: 200px;
  right: 0;
  bottom: -270px;
  transform: rotate(294deg);
}
.my-table .leaf2{
  background-image: url('./images/cherry-blossom.png');
  background-repeat: no-repeat;
  height: 650px;
  background-size: 100%;
  position: absolute;
  width: 200px;
  right: 0;
  top: -270px;
  transform: rotate(294deg);
}
.find-table{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.find-table .tb-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.find-table .find-title{
  font-size: 2rem;
  color: #e992a6;
  font-style: italic;
}
.find-table .input{
  width: 400px;
  height: 50px;
  font-size: 1.4rem;
  padding: 5px 10px;
  border: 4px solid #e992a6;
  margin: 30px 0 15px;
}
.find-table .input::-webkit-input-placeholder {
  color: grey;
  font-family: 'Charm', cursive;
}
.find-table .find-btn{
  font-size: 1.6rem;
  font-weight: 600;
  font-style: italic;
  background: #e992a6;
  padding: 5px 60px;
  border-radius: 30px;
  color: white;
  cursor: pointer;
}
.find-table .table-mates{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.find-table .table-mates .tm-title{
  font-size: 1.5rem;
  font-style: italic;
  font-family: 'Charm';
  color: #757272;
  padding: 10px 0;
}
.find-table .table-mates ul{
  list-style: none;
  width: 400px;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 400px;
  overflow-y: auto;
}
.find-table .table-mates ul li{
  font-size: 1.5rem;
  font-style: italic;
  padding: 5px;
  border: 1px solid #e992a6;
  margin: 15px 0;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: #bc4b65;
  text-transform: capitalize;
}
.my-table .table-detail{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-table .table-detail .w1{
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
}
.my-table .table-detail .f1{
  font-size: 1.8rem;
  color: grey;
}
.my-table .table-detail .my-name{
  font-size: 2.5rem;
  color: #e5748e;
  font-weight: 600;
  text-transform: capitalize;
}
.my-table .table-detail .tabele-mates{
  list-style: none;
  width: 400px;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 280px;
  overflow-y: auto;
}
.my-table .table-detail .tabele-mates li{
  font-size: 1.3rem;
  font-style: italic;
  padding: 5px;
  border: 1px solid #e992a6;
  margin: 10px 0;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: #d45e79;
  text-transform: capitalize;
}
.back-btn{
  font-size: 1.3rem;
  font-family: 'Baskervville';
  font-style: italic;
  background-color: #e992a6;
  width: auto;
  height: 38px;
  color: white;
  text-decoration: none;
  border-radius: 35px;
  padding: 0px 15px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noGuest{
  color: #bc4b65;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 320px)  and (max-width: 640px){
  .weddingApp {
    max-width:100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: fixed;
  }
  .nav-btn{
    font-size: 1.15rem;
    padding: 8px 25px;
  }
  .welcome{
    margin-bottom: 20px;
  }
  .wl-note {
    font-size: 1.1rem;
    letter-spacing: 1.5px;
  }
  .welcome .couples{
    display: flex;
    align-items: center;
    margin: 2px 0;
    width: 230px;
    font-size: 2.2rem;
  }
  .loc{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location{
    font-size: 0.8rem;
    letter-spacing: 1.9px;
  }
  .nav-btn-container{
    margin-top: 6px;
    padding: 15px 0;
  }
  .cplImg {
    height: 250px;
  }
  .find-table{
    margin: 50px 0;
  }
  .find-table .tb-form{
    width: 320px;
  }
  .find-table .find-title{
    text-align: center;
    font-size: 1.5rem;
  }
  .find-table .input{
    width: 295px;
    padding: 2px 8px;
    height: 30px;
    font-size: 1rem;
    line-height: 0.8rem;
    margin: 15px 0;
  }
  .find-table .find-btn{
    font-size: 1.2rem;
  }
  .find-table .table-mates{
    height: calc(calc(var(--vh, 1vh) * 100) - 285px);
  }
  .find-table .table-mates ul{
    width: 320px;
    height: calc(calc(var(--vh, 1vh) * 100) - 315px);
  }
  .find-table .table-mates .tm-title{
    font-size: 1rem;
    padding: 0;
  }
  .find-table .table-mates ul li{
    font-size: 0.85rem;
    margin: 7px 0;
  }
  .my-table .table-detail{
    align-items: center;
    margin: 42px 0 20px;
  }
  .my-table .table-detail .f1{
    font-size: 1.1rem;
  }
  .my-table .table-detail .my-name{
    font-size: 1.5rem;
  }
  .my-table .table-detail .tabele-mates{
    width: 320px;
    height: calc(calc(var(--vh, 1vh) * 100) - 286px);
  }
  .my-table .table-detail .tabele-mates li{
    font-size: 0.9rem;
  }
  .my-table .table-detail .tabele-mates li{
    margin: 7px 0;
    padding: 4px;
  }
  .back-btn{
    padding: 0px 15px;
    height: 30px;
    font-size: 1rem;
  }
  .cherryFlower{
    background-position: 0 35px;
  }
  .cherryFlowerBtm{
    background-position: 0 35px;
    transform: rotate(349deg);
  }
  .my-table .leaf1{
    background-repeat: no-repeat;
    height: 526px;
    background-size: 85%;
    position: absolute;
    width: 140px;
    right: 65%;
    bottom: -260px;
    transform: rotate(92deg);
  }
  .my-table .leaf2{
    background-repeat: no-repeat;
    height: 526px;
    background-size: 85%;
    position: absolute;
    width: 140px;
    right: 0;
    top: -260px;
    transform: rotate(272deg);
  }
  .noGuest{
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    align-items: center;
  }
  .noGuest .sr{
    font-size: 2rem;
  }
  .thankyou-note{
    font-family: 'Charm';
    font-size: 1.5em;
    color: #c47a8b;
    margin: 10px 0;
    text-align: center;
    padding: 0 50px;
  }
  .cplImg.thnk{
    height: 230px;
  }
}

@media (min-width: 768px) and (max-width: 1366px){
  .weddingApp {
    max-width:100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: fixed;
  }
  .nav-btn{
    font-size: 1.75rem;
  }
  .back-btn{
    font-size: 1.7rem;
    height: 55px;
    padding: 0px 23px;
  }
  .wl-note {
    font-size: 1.9rem;
    letter-spacing: 4px;
  }
  .loc{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location{
    font-size: 1.4rem;
    letter-spacing: 2.8px;
  }
  .welcome .couples{
    width: 450px;
    font-size: 4.5rem;
    display: flex;
    margin: 0;
    line-height: 4.5rem;
  }
  .cplImg {
    height: 450px;
  }
  .find-table{
    margin-top: 92px;
  }
  .find-table .tb-form {
    width: 620px;
  }
  .find-table .find-title{
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
  .find-table .input {
    width: 400px;
    padding: 5px 8px;
    height: 45px;
    font-size: 1.8rem;
    line-height: 1rem;
  }
  .find-table .find-btn {
    font-size: 1.8rem;
  }
  .find-table .table-mates{
    height: calc(calc(var(--vh, 1vh) * 100) - 400px);
  }
  .my-table{
    padding: 0;
  }
  .find-table .table-mates ul {
    width: 400px;
    height: calc(calc(var(--vh, 1vh) * 100) - 465px);
  }
  .find-table .table-mates ul li {
    font-size: 1.5rem;
  }
  .my-table .table-detail .tabele-mates {
    width: 400px;
    height: calc(calc(var(--vh, 1vh) * 100) - 447px);
  }
  .my-table .table-detail .f1 {
    font-size: 1.9rem;
  }
  .my-table .table-detail .my-name {
    font-size: 2.5rem;
    padding: 0;
  }
  .my-table .table-detail .tabele-mates li {
    font-size: 1.6rem;
  }
  .cherryFlower{
    background-position: 12px 0px;
    background-size: 39%;
    height: 200px;
  }
  .cherryFlowerBtm{
    background-position: 5px 142px;
    transform: rotate(351deg);
    height: 336px;
    width: 346px;
    background-size: 100%;
    bottom: -41px;
  }
  .my-table .leaf1{
    height: 650px;
    background-size: 84%;
    position: absolute;
    width: 255px;    
    right: -69px;
    bottom: -263px;
    transform: rotate(271deg);
  }
  .my-table .leaf2{
    height: 650px;
    background-size: 84%;
    position: absolute;
    width: 255px;
    left: -66px;
    top: -275px;
    transform: rotate(94deg);
  }
  .my-table .table-detail .w1{
    margin-top: 60px;
  }
  .noGuest{
    display: flex;
    flex-direction: column;
    font-size: 2.2rem;
    align-items: center;
  }
  .noGuest .sr{
    font-size: 2.8rem;
  }
  .thankyou-note{
    font-family: 'Charm';
    font-size: 2.5em;
    color: #c47a8b;
    margin: 35px 0;
  }
  .cplImg.thnk{
    height: 350px;
  }
}

@media (min-width: 992px) {
  .weddingApp {
    max-width: 800px;
  }
  .welcome{
    margin-bottom: 18px;
  }
  .nav-btn-container{
    margin-top: 0;
  }
  .nav-btn{
    font-size: 1.2rem;
    padding: 6px 30px;
  }
  .wl-note {
    font-size: 1.25rem;
    letter-spacing: 4px;
  }
  .welcome .couples{
    width: 300px;
    font-size: 3rem;
    display: flex;
    margin: 0;
  }
  .loc{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location{
    font-size: 0.9rem;
    letter-spacing: 2px;
  }
  .cplImg {
    height: 350px;
  }
  .find-table{
    margin-top: 15px;
  }
  .find-table .tb-form {
    width: 600px;
    margin-top: 20px;
  }
  .find-table .input{
    width: 400px;
    padding: 0 8px;
    height: 30px;
    font-size: 1.1rem;
    line-height: 1rem;
    margin: 3px;
  }
  .find-table .table-mates .tm-title{
    font-size: 1.2rem;
  }
  .find-table .table-mates {
    height: calc(100vh - 250px);
  }
  .find-table .table-mates ul {
    width: 400px;
    height: calc(100vh - 310px);
  }
  .find-table .table-mates ul li{
    font-size: 1.1rem;
    margin: 5px 0;
    padding: 2px;
  }
  .my-table .table-detail .w1 {
    margin-top: 0px;
  }
  .my-table .table-detail .f1{
    font-size: 1.25rem;
  }
  .my-table .table-detail .my-name{
    font-size: 1.82rem;
  }
  .my-table .table-detail .tabele-mates {
    width: 400px;
    height: calc(100vh - 335px);
  }
  .my-table .table-detail .tabele-mates li{
    font-size: 1.1rem;
    margin: 5px 0;
    padding: 2px;
  }
  .my-table {
    padding: 40px 0;
  }
  .my-table .leaf2{
    right: -160px;
    left: inherit;
    top: -182px;
    background-size: 71%;
    transform: rotate(308deg);
  }
  .my-table .leaf1{
    background-size: 75%;
    left: -112px;
    bottom: -175px;
    transform: rotate(112deg);
  }
  .find-table .find-title{
    font-size: 1.5rem;
  }
  .back-btn{
    padding: 5px 15px;
    cursor: pointer;
    font-size: 1.2rem;
    height: auto;
  }
  .noGuest{
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    align-items: center;
  }
  .noGuest .sr{
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .weddingApp {
    max-width: 800px;
  }
}